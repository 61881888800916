import { AxiosError } from "axios";

type ResponseErrorStatus = "";

interface Errors {
  [key: string]: string[] | Errors;
}

export interface ResponseError {
  status: ResponseErrorStatus;
  detail: string;
  errors: Errors | string | null;
}

export class NetworkError<T extends object = ResponseError> extends Error {
  public errors: Partial<Errors> = {};
  public status?: ResponseErrorStatus;

  public responseStatus?: number;

  constructor(axiosError: AxiosError<ResponseError>) {
    const responseData = axiosError.response?.data;
    super(responseData?.detail || axiosError.message);
    if (responseData) {
      this.errors = responseData.errors as Errors;
      this.status = responseData.status;
    }

    this.responseStatus = axiosError.response?.status;
    this.stack = axiosError.stack;
  }

  public getFieldError(name: keyof T): Errors | string[] | null {
    return this.errors[name] || null;
  }

  public getFirstFieldError(name: keyof T): string | null {
    return this.getFieldError(name)?.[0] || null;
  }
}
