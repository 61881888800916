import { FC, useEffect } from "react";

import Close12Icon from "icons/cross12.svg?react";

import Button from "basics/button";

import { getCSSVariables } from "selectors/common";

import {
  ALERT_ICONS_MAP,
  CLOSE_TIMEOUT_MAP,
  ICON_SHADOW_COLORS,
  PROGRESS_COLORS,
} from "./duck/constants";
import { useDialog } from "../duck/hooks";

import classes from "./styles/classes.module.scss";

const Alert: FC<Partial<Alert>> = ({ type, message }) => {
  const { resolve } = useDialog();

  const Icon = ALERT_ICONS_MAP[type];
  const closingDelay = CLOSE_TIMEOUT_MAP[type];

  useEffect(() => {
    const timer = setTimeout(() => resolve(null), closingDelay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      role="alert"
      style={getCSSVariables({
        animationDelay: `${closingDelay}ms`,
        backgroundColor: PROGRESS_COLORS[type],
        boxShadowColor: ICON_SHADOW_COLORS[type],
      })}
      className={classes.wrapper}
    >
      <Icon className={classes.icon} />
      <p className={classes.text}>{message}</p>
      <Button
        themeName="ghost"
        onClick={() => {
          resolve(null);
        }}
        className={classes.closeButton}
      >
        <Close12Icon className={classes.closeButtonIcon} />
      </Button>
      <div className={classes.progressLoader} />
    </div>
  );
};

export default Alert;
