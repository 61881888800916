import { FunctionComponent, SVGProps } from "react";

import Error40Icon from "icons/error40.svg?react";

import { TimeMilliseconds } from "constants/time";

export const ALERT_ICONS_MAP: Record<
  Alert["type"],
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  error: Error40Icon,
};

export const CLOSE_TIMEOUT_MAP: Record<Alert["type"], number> = {
  error: TimeMilliseconds.second * 7,
};

export const PROGRESS_COLORS: Record<Alert["type"], string> = {
  error: "#f74e31",
};

export const ICON_SHADOW_COLORS: Record<Alert["type"], string> = {
  error: "#ff0d0d66",
};
