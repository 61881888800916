import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import isObject from "lodash/isObject";

/*
 * Recursively converts an object keys into snake case
 * This's the inverse of camelize
 */
export function snakelize<T>(obj: Array<T>): ToSnakeCase<T>;
export function snakelize<T>(obj: T): ToSnakeCase<T>;
export function snakelize<T extends object>(obj: T): ToSnakeCase<T>;
export function snakelize(obj: unknown): unknown {
  if (!isObject(obj)) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(snakelize);
  }

  const result: Record<string, unknown> = {};
  for (const key in obj) {
    const value = (obj as Record<string, unknown>)[key];

    result[snakeCase(key)] = snakelize(value);
  }

  return result;
}

/*
 * Recursively converts an object keys into camel case
 * This's the inverse of snakelize
 */
export function camelize<T>(obj: T): ToCamelCase<T>;
export function camelize<T>(obj: Array<T>): ToCamelCase<Array<T>>;
export function camelize<T extends object>(obj: T): ToCamelCase<T>;
export function camelize(obj: unknown): unknown {
  if (!isObject(obj)) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(camelize);
  }

  const result: Record<string, unknown> = {};
  for (const key in obj) {
    const value = (obj as Record<string, unknown>)[key];

    result[camelCase(key)] = camelize(value);
  }

  return result;
}
